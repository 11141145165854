<script>
import simplebar from "simplebar-vue";
// import axios from 'axios';
export default {
  data() {
    return {
      notificationList: [],
    };
  },
  components: { simplebar },
  mounted() {
    this.getNotification();
    // window.setInterval(() => {
    //   this.getNotification();
    // }, 30000);
  },
  methods: {
    getNotification() {
      this.$store.getters.client.get("/order/notification/").then((response) => {
        let notification = response.data.results
        this.notificationList = notification.filter(x => x.mark_as_read === false)
      });
    },
    markRead(id) {
      this.$store.getters.client.patch(`/order/notification/${id}/`, { mark_as_read: true }).then(() => {
        this.getNotification()
      })
    },
    logOut() {
      this.$store.commit("removeToken");
      this.$router.push({ name: "Login" });
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box bg-white">

          <a class="logo logo-light">
            <span class="logo-lg logo-text">
              <img src="./../assets/images/logo-admin.png" class="w-100" alt="">
            </span>
          </a>
        </div>

        <button @click="toggleMenu" type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect"
          id="vertical-menu-btn">
          <i class="ri-menu-2-line align-middle"></i>
        </button>

        <!-- App Search-->
        <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input type="text" class="form-control" :placeholder="$t('navbar.search.text')" />
            <span class="ri-search-line"></span>
          </div>
        </form>
      </div>

      <div class="d-flex">
        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button type="button" class="btn header-item noti-icon waves-effect" @click="initFullScreen">
            <i class="ri-fullscreen-line"></i>
          </button>
        </div>

        <b-dropdown right menu-class="dropdown-menu-lg p-0" toggle-class="header-item noti-icon" variant="black">
          <template v-slot:button-content>
            <i class="ri-notification-3-line"></i>
            <span class="noti-dot" v-if="notificationList.length > 0"></span>
          </template>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ $t('navbar.dropdown.notification.text') }}</h6>
              </div>
              <div class="col-auto">
                <a href="#!" class="small">{{ $t('navbar.dropdown.notification.subtext') }}</a>
              </div>
            </div>
          </div>
          <simplebar style="max-height: 230px;">
            <div class="text-reset notification-item" v-for="notification in notificationList" :key="notification.id"
              @click="markRead(notification.id)">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="mdi mdi-alert-circle-outline"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ notification.title }}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ notification.message }}</p>
                  </div>
                </div>
              </div>
            </div>
          </simplebar>
          <div class="p-2 border-top">
            <router-link class="btn btn-sm btn-link font-size-14 btn-block text-center" to="/notifications">
              <i class="mdi mdi-arrow-right-circle mr-1"></i>
              {{ $t('navbar.dropdown.notification.button') }}
            </router-link>
          </div>
        </b-dropdown>

        <b-dropdown right variant="black" toggle-class="header-item" class="d-inline-block user-dropdown">
          <template v-slot:button-content>
            <!-- <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/avatar-2.jpg"
              alt="Header Avatar"
            /> -->
            <span class="d-none d-xl-inline-block ml-1">
              {{ $store.state.Auth.email }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <a class="dropdown-item" href="#">
            <i class="ri-user-line align-middle mr-1"></i>
            {{ $t("navbar.dropdown.kevin.list.profile") }}
          </a>

          <a class="dropdown-item d-block" href="#">
            <!-- <span class="badge badge-success float-right mt-1"></span> -->
            <i class="ri-settings-2-line align-middle mr-1"></i>
            {{ $t("navbar.dropdown.kevin.list.settings") }}
          </a>

          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" @click="logOut">
            <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            {{ $t("navbar.dropdown.kevin.list.logout") }}
          </a>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.notify-item {
  .active {
    color: #16181b;
    background-color: #f8f9fa;
  }
}

.logo-text {
  font-size: 13px;
  color: white;
}
</style>
