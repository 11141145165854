export const menuItems = [
  {
    id: 1,
    label: "menuitems.menu.text",
    isTitle: true,
  },
  {
    id: 2,
    label: "Dashboard",
    icon: "fas fa-home",
    link: "/dashboard",
  },
  {
    id: 3,
    label: "Product Catalogue",
    isTitle: true,
  },
  {
    id: 4,
    label: "Categories",
    icon: " fas fa-link",
    link: "/categories",
  },
  {
    id: 104,
    label: "Promotional Categories",
    icon: " fas fa-link",
    link: "/promotional-categories",
  },
  {
    id: 5,
    label: "Attributes",
    icon: "fas fa-filter",
    link: "/attributes",
  },
  {
    id: 7,
    icon: "fas fa-infinity",
    label: "Products",
    link: "/products",
  },
  {
    id: 78,
    icon: "fas fa-infinity",
    label: "Promotional Products",
    link: "/promotional-products",
  },
  {
    id: 23,
    icon: "fas fa-tshirt",
    label: "Custom Products",
    link: "/custom-products",
  },
  {
    id: 70,
    icon: "fas fa-infinity",
    label: "Manage Products",
    link: "/manage-product",
  },
  {
    id: 79,
    icon: "far fa-calendar-alt",
    label: "Calendar",
    link: "/calendar",
  },
  {
    id: 8,
    label: "Utility",
    isTitle: true,
  },
  {
    id: 108,
    icon: "ri-service-fill",
    label: "Home Page",
    link: "/home-page",
  },
  {
    id: 109,
    icon: "ri-service-fill",
    label: "Papersize",
    link: "/paper-size",
  },
  {
    id: 110,
    icon: "ri-service-fill",
    label: "Reviews",
    link: "/reviews",
  },
  // {
  //   id: 18,
  //   label: "Studio",
  //   isTitle: true,
  // },
  {
    id: 119,
    icon: "ri-video-fill",
    label: "Team",
    link: "/team-members",
  },
  {
    id: 20,
    label: "Blogs",
    isTitle: true,
  },
  {
    id: 21,
    icon: "ri-video-fill",
    label: "Manage Blogs",
    link: "/blogs",
  },
  {
    id: 10,
    label: "Order",
    isTitle: true,
  },
  {
    id: 11,
    icon: "fas fa-atom",
    label: "Order Management",
    link: "/orders",
  },
  {
    id: 12,
    icon: "ri-t-box-fill",
    label: "Transactions",
    link: "/transactions",
  },
  {
    id: 13,
    icon: " ri-user-received-fill",
    label: "Customers",
    link: "/customers",
  },
  {
    id: 6,
    icon: " ri-mail-send-line",
    label: "Emails",
    link: "/news-letter",
  },
  {
    id: 14,
    icon: " ri-bar-chart-2-fill",
    label: "Analytics",
    link: "/analytics",
  },
  {
    id: 15,
    icon: " ri-file-edit-line",
    label: "Quotation",
    link: "/quotation",
  },
  {
    id: 16,
    icon: " ri-price-tag-fill",
    label: "Purchase Order",
    link: "/purchase",
  },
  {
    id: 17,
    icon: " ri-coupon-line",
    label: "Discount Coupons",
    link: "/discount",
  },
  {
    id: 22,
    label: "User",
    isTitle: true,
  },
  {
    id: 19,
    icon: "ri-video-fill",
    label: "Re-Sellers",
    link: "/re-sellers",
  },
  {
    id: 39,
    icon: "ri-video-fill",
    label: "Studio User",
    link: "/studio",
  },
  {
    id: 40,
    icon: "ri-video-fill",
    label: "Seller Management",
    link: "/seller",
  },
  {
    id: 9,
    icon: "ri-service-fill",
    label: "Supplier Management",
    link: "/supplier",
  },
];
