<script>
import { layoutComputed } from "@/state/helpers";
import Vertical from "./vertical";
import PageHeader from "@/components/page-header";
import Horizontal from "./horizontal";

export default {
  components: { Vertical, Horizontal, PageHeader },
  data() {
    return {
      title: "",
      items: [],
    };
  },
  computed: {
    ...layoutComputed,
  },
  methods: {
    getRoutes() {
      this.title = this.$route.name;
    },
    verfiyToken() {
      if (
        this.$store.state.Auth.access === "null" ||
        this.$store.state.Auth.access === null ||
        this.$store.state.Auth.access === undefined
      ) {
        this.$router.push({ name: "Login" });
      }
    },
  },
  mounted() {
    this.getRoutes();
    this.verfiyToken();
  },
  watch: {
    $route() {
      this.items = [];
      this.items.push({ text: this.$route.name, href: "/" });
      this.title = this.$route.name;
      this.show = false;
    },
  },
};
</script>

<template>
  <div>
    <vertical v-if="layoutType === 'vertical'" :layout="layoutType">
      <PageHeader :title="title" :items="items" />
      <slot />
      <router-view />
    </vertical>
    <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
      <slot />
    </Horizontal>
  </div>
</template>
